<script>
  import './registerGlobalsSsr'; // IMPORTANT: IMPORT GLOBAL MIXINS ONLY ONCE, OTHERWISE WE'LL GET A MEMORY LEAK
  import { getUrlParams } from '@utilities/mrVueUtils';
  import { mapActions, mapMutations, mapState } from "vuex";

  // Components
  import MrNavigation from "@components/MrNavigation";
  import ChatWidget from "@components/ChatWidget";
  import AppModal from "@components/AppModal";
  import SiteMessageBannerCarousel from '@components/SiteMessageBannerCarousel';
  import SiteMessageBanner from '@components/SiteMessageBanner';
  import toggle from '@components/toggle';
  import toggleInterval from '@components/toggleInterval';
  import interval from '@components/interval';
  import EmailCaptureModal from '@components/EmailCapture/EmailCaptureModal';
  import PhoneCaptureModal from '@components/EmailCapture/PhoneCaptureModal';
  import Notifications from '@components/Notifications';
  import StickyPromoDrawer from '@components/StickyPromoDrawer';

  const MrBday = () => import('@components/MrBday');
  const Breadcrumbs = () => import('@components/Breadcrumbs');
  const SlickCarouselSsr = () => import('@components/SlickCarouselSsr');
  const SimpleCarouselSSR = () => import('@components/SimpleCarouselSSR');
  const ReactiveToItemsCarousel = () => import('@components/Carousels/ReactiveToItemsCarousel');
  const JustForBestExperience = () => import(/* webpackChunkName: 'JustForBestExperience' */ '@components/Experience/JustForBestExperience');
  const HairColorSimulator = () => import(/* webpackChunkName: 'HairColorSimulator' */ '@components/AugmentedReality/HairColorSimulator');
  const ReinventedAccordion = () => import(/* webpackChunkName: 'ReinventedAccordion' */ '@components/ReinventedAccordion/ReinventedAccordion');
  const ClosestHairColorBar = () => import(/* webpackChunkName: 'ClosestHairColorBar' */ '@components/ClosestHairColorBar');
  const UnlimitedRootsMembershipPricing = () => import(/* webpackChunkName: 'UnlimitedRootsMembershipPricing' */ '@components/UnlimitedRootsMembershipPricing');
  const StoreValue = () => import(/* webpackChunkName: 'StoreValue' */ '@components/StoreValue');
  const HelpCenter = () => import(/* webpackChunkName: 'HelpCenter' */ '@components/HelpCenter');

  // Blog
  const BlogSearch = () => import('@components/Blog/BlogSearch');
  const BlogSocialNetworks = () => import('@components/Blog/BlogSocialNetworks');
  const RelatedProducts = () => import('@components/Blog/RelatedProducts');
  const BlogDisqus = () => import('@components/Blog/BlogDisqus');
  const BlogPosts = () => import('@components/Blog/BlogPosts');

  // Homepage
  const ReturnUserModule = () => import(/* webpackChunkName: "HomePage" */ '@components/HomePage/ReturnUserModule');
  const UgcWithReviews = () => import(/* webpackChunkName: 'HomePage' */ '@components/HomePage/UgcWithReviews');
  const ContactUs = () => import(/* webpackChunkName: 'HomePage' */ '@components/HomePage/ContactUs');
  const HomeHero = () => import(/* webpackChunkName: "HomePage" */ '@components/HomePage/HomeHero');
  const WhyMR = () => import(/* webpackChunkName: 'HomePage' */ '@components/HomePage/WhyMR');
  const HomeBlogSection = () => import(/* webpackChunkName: 'HomePage' */ '@components/HomePage/HomeBlogSection');
  const UgcHomeCarousel = () => import(/* webpackChunkName: 'UgcHomeCarousel' */ '@components/UgcHomeCarousel');
  const ProductsSwatchesWithUgc = () => import(/* webpackChunkName: 'ProductsSwatchesWithUgc'*/ '@components/ProductsSwatchesWithUgc');

  // Footer
  const MrFooter = () => import('@components/MrFooter/MrFooter');
  const MrNewsletterSubscription = () => import('@components/MrNewsletterSubscription');

  // Mixins
  import cartMixin from "@mixins/cartMixin";
  import mainAppMixin from "@mixins/mainAppMixin";
  import browserContextMixin from "@mixins/browserContextMixin";

  // Hair Color Bar
  const HairColorBarGuestWifi = () => import(/* webpackChunkName: 'HairColorBarGuestWifi' */ '@components/HairColorBar/GuestWifi/HairColorBarGuestWifi');
  const HcbServicesOffered = () => import('@components/HcbServicesOffered');
  const HcbLocationPage = () => import('@components/HairColorBar/HcbLocationPage');
  const ColorBarMapSectionV2 = () => import('@components/ColorBarMapSectionV2');

  // Shop
  const ShopProductCategory = () => import(/* webpackChunkName: 'Shop' */ '@components/Shop/ShopProductCategory');
  const ShopByColor = () => import('@components/ShopByColor');

  // Misc
  const ToggleableCards = () => import(/* webpackChunkName: 'ToggleableCards' */ '@components/Cards/ToggleableCards');
  const CardsCarousel = () => import(/* webpackChunkName: 'CardsCarousel' */ '@components/Carousels/CardsCarousel');
  const CardFlips = () => import(/* webpackChunkName: "CardFlips" */ '@components/CardFlips');
  const CmsPartialSsr = () => import(/* webpackChunkName: 'CmsPartial' */ '@components/CmsPartialSsr');

  const EmailCaptureForm = () => import(/* webpackChunkName: 'EmailCaptureForm' */ '@components/EmailCapture/EmailCaptureForm');
  const ColorWonderHero = () => import(/* webpackChunkName: 'ColorWonderHero' */ '@components/ColorWonder/ColorWonderHero');
  const ColorwonderExpectations = () => import(/* webpackChunkName" 'ColorWonderLanding' */ '@components/ColorwonderExpectations');

  export default {
    name: "App",
    components: {
      EmailCaptureForm,
      CmsPartialSsr,
      MrNavigation,
      MrFooter,
      MrNewsletterSubscription,
      MrBday,
      ChatWidget,
      AppModal,
      SiteMessageBannerCarousel,
      SiteMessageBanner,
      SlickCarouselSsr,
      SimpleCarouselSSR,
      ReactiveToItemsCarousel,
      JustForBestExperience,
      HairColorSimulator,
      ReinventedAccordion,
      ClosestHairColorBar,
      toggle,
      toggleInterval,
      interval,
      StoreValue,
      BlogSearch,
      BlogSocialNetworks,
      RelatedProducts,
      BlogDisqus,
      BlogPosts,
      EmailCaptureModal,
      PhoneCaptureModal,
      Notifications,
      StickyPromoDrawer,
      ReturnUserModule,
      UgcWithReviews,
      ContactUs,
      HomeHero,
      HomeBlogSection,
      WhyMR,
      CardsCarousel,
      UgcHomeCarousel,
      ProductsSwatchesWithUgc,
      Breadcrumbs,
      UnlimitedRootsMembershipPricing,
      HairColorBarGuestWifi,
      ShopProductCategory,
      ToggleableCards,
      CardFlips,
      HelpCenter,
      HcbServicesOffered,
      ShopByColor,
      ColorWonderHero,
      ColorwonderExpectations,
      HcbLocationPage,
      ColorBarMapSectionV2,
    },

    mixins: [
      cartMixin,
      mainAppMixin,
      browserContextMixin,
      // "globalMixins" is imported from the top of this file from "registerGlobalsSsr.js"
    ],

    data: () => ({
      isAppMounted: false,
    }),

    computed: {
      ...mapState('customer', [
        'info',
        'cdata',
      ]),

      ...mapState('global', [
        'urlParams',
        'isApp',
        'headerClass',
        'showHamburgerMenu',
        'hideLogin',
        'removeFaqsAndTerms',
        'changeFaqsAndTerms',
        'simpleFooter',
        'showNavs',
        'appHtml',
        'breadcrumbs',
        'simpleHtml'
      ]),

      showChatWidget() {
        return this.isAppMounted && !this.isApp;
      },

      customerInfo() {
        return this.$store.state.customer.info;
      },

      hideSitewideBanner() {
        return this.$isServer ? false : window.__CONTENT_HIDE_SITEWIDE_BANNER__;
      },

      hideStickyPromoDrawer() {
        return this.$isServer ? true : window.__CONTENT_HIDE_STICKY_PROMO_DRAWER__;
      },

      siteMessageBannerCarouselTopics() {
        return this.$isServer ? [] : window.__CONTENT_SITE_MESSAGE_TOPICS__;
      },

      appModalVisible() {
        return this.$store.state.modal.visible;
      },

      inColorAdvisorEmailCaptureExperiment() {
        return this.experiments["Advisor Email Capture 2022"] === "B";
      },
    },

    created() {
      // Set the global SSR app flag for making conditional decisions on components logic
      this.setIsVueSSRApp(true);
      this.setCdataUserSettings();
      if (this.$isServer) {
        this.setSimpleHtml(this.$ssrContext.simpleHtml);
      }

      let fullSsrContentClasses = [];
      let cmsContentId = null;

      if (this.$ssrContext) {
        fullSsrContentClasses = this.$ssrContext.computedContentClasses;
        cmsContentId = this.$ssrContext.cmsContentId;
      }

      let fullContentClasses = [...fullSsrContentClasses, 'background-white'];

      this.$options.template = `
      <div id="mrVueApp" :class="{'no-scroll': windowData.noScroll || appModalVisible}" @click="setTopNavSelected(0)">
        <a class="skip-to-content-link" href="#content" tab-index="0">Skip to Main Content</a>
        ${this.simpleHtml ? '' : `
          <SiteMessageBanner v-if="isAppMounted  && !hideSitewideBanner" topics="sitewide"></SiteMessageBanner>
          <SiteMessageBannerCarousel v-if="isAppMounted" :topics="siteMessageBannerCarouselTopics" :use-utm-topic="true"></SiteMessageBannerCarousel>
          <MrNavigation v-if="!isApp" :using-sticky-promo-drawer="!hideStickyPromoDrawer"></MrNavigation>
        `}

        <div id="content" class="${fullContentClasses.join(' ')}" data-cms-cid="${cmsContentId}">
          ${this.simpleHtml ? '' : `
            <Breadcrumbs v-if="breadcrumbs" :fragments="breadcrumbs"></Breadcrumbs>
          `}
          ${this.simpleHtml ? '' : `
            <StickyPromoDrawer v-if="!isApp && isAppMounted && !hideStickyPromoDrawer"></StickyPromoDrawer>
          `}

          ${this.$isServer ? this.$ssrContext.html : this.appHtml}
        </div>


        ${this.simpleHtml ? '' : `
          <MrFooter v-if="!isApp && isAppMounted"
            :simple-footer="simpleFooter"
            :removeFaqsAndTerms="removeFaqsAndTerms"
            :changeFaqsAndTerms="changeFaqsAndTerms"
            class="${fullSsrContentClasses.join(' ')}">
          </MrFooter>

          <ChatWidget v-if="showChatWidget"></ChatWidget>
          <AppModal v-if="isAppMounted"></AppModal>
          <EmailCaptureModal v-if="isAppMounted"></EmailCaptureModal>
          <PhoneCaptureModal v-if="isAppMounted"></PhoneCaptureModal>
          <Notifications v-if="isAppMounted"></Notifications>
        `}
      </div>
    `;
    },

    mounted() {
      this.setMRConfig(window.MR.config);
      this.loadCustomer();
      this.setCdata(window.cdata);
      this.checkAuth();
      this.runSegmentTracking();
      // TODO: move all logic to the store?
      this.setUrlParams(getUrlParams());
      this.setDeviceSizeSettings();
      this.checkEmailCaptureModal();
      this.initCoupons();

      this.isAppMounted = true;
      this.updateCartFromCookies();

      const { utm_partial_type, utm_partial } = this.urlParams;
      if (utm_partial_type && utm_partial) {
        if (utm_partial_type === 'modal') {
          this.showPartialModal(utm_partial);
        }
      }
      this.setExperiments(window.experiments || {});
      window.trackExperimentViewed = this.mix_trackExperimentViewed;

      window.addToCart = this.addToCart;
    },
    methods: {
      ...mapActions('cart', ['addToCart', 'updateCartFromCookies']),
      ...mapActions('customer', ['loadCustomer', 'refreshCustomerCdata']),
      ...mapMutations('customer', ['setCdata']),
      ...mapMutations('global', [
        'setMRConfig',
        'setIsVueSSRApp',
        'setUrlParams',
        'setSimpleHtml',
        'setExperiments'
      ]),
      ...mapMutations('siteNav', [
        'setTopNavSelected',
        'setTopNavMobileNavOpen',
      ]),
      ...mapActions('emailCapture', ['runEmailCapture']),
      ...mapActions('partial', [
        'showPartialModal',
      ]),
      ...mapActions('coupons', [
        'initCoupons'
      ]),

      checkEmailCaptureModal() {
        if (this.inColorAdvisorEmailCaptureExperiment) {
          return;
        }

        if (!this.urlParams['extole_zone_name']) {
          this.runEmailCapture();
        }
      },

      /**
       * SSR only: takes real user based data injected by the back-end and sets it as CDATA in Vuex for rendering the right
       * components based on CDATA attributes such as "visitorType" and "bestColorMatch"
       * @see https://madison-reed.atlassian.net/wiki/spaces/DOTCOM/pages/979992588/How+to+manage+user-based+data+components+in+SSR+for+above+the+fold+content
       * @see htmlRenderer.js check where the function "serverSideRenderVueContent" is used
       */
      setCdataUserSettings() {
        if (process.env.IS_SSR_APP && !process.env.IS_BROWSER && this.$ssrContext.userBasedCData) {
          this.setCdata(this.$ssrContext.userBasedCData);
        }
      }
    },
    template: '',
  };
</script>
